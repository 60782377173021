<script setup lang="ts">
import TheIcon from '@/Components/TheIcon.vue'
import ToggleSection from '@/Pages/the-city/components/ToggleSection.vue'
import { onMounted, ref } from 'vue'
import { renderTableOfContent } from '@/src/slp'

const showContent = ref(false)

const toggleShowContent = (): void => {
    showContent.value = !showContent.value
}

onMounted(() => {
    renderTableOfContent()
})
</script>

<template>
    <div class="slp-table-content-accordion-wrap mb-3" :class="{'slp-table-content-accordion-wrap--open': showContent}">
        <ToggleSection :is-open="showContent" :show-arrow="false">
            <template #title>
                <div
                    role="tab"
                    class="card-header slp-table-content-accordion-title py-0 w-100 d-flex justify-content-between align-items-center"
                    :class="{'slp-table-content-accordion-open': showContent}"
                    @click="toggleShowContent()"
                >
                    <span class="accordion-link">Table of Content: The Perfect Guide to Hostels</span>

                    <TheIcon
                        icon-id="arrow-bottom-2"
                        width="18"
                        height="18"
                        class="icon"
                        :class="{'rotate-icon': showContent}"
                    />
                </div>
            </template>
            <template #section>
                <div class="card border-0">
                    <div class="card-body py-5 ">
                        <div id="table-of-contents" />
                        <p class="mb-0">
                            Always keep exploring: Find exactly what you're looking for by
                            jumping to your preferred sections.
                        </p>
                    </div>
                </div>
            </template>
        </ToggleSection>
    </div>
</template>

<style scoped lang="scss">
.card {
    background-color: transparent;
}

.icon {
    transition: transform 0.3s linear;
}

.rotate-icon {
    transform: rotate(180deg);
}

:deep(.toggle-element) {
    box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.08), inset 0px -1px 0px rgba(14, 14, 44, 0.24);
    border-radius: 16px;
}

.slp-table-content {
    &-accordion {
        box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.08), inset 0px -1px 0px rgba(14, 14, 44, 0.24);
        border-radius: 5px 5px 16px 16px;

        &-title {
            transition: box-shadow .3s linear, border-radius .3s linear;
            border-radius: 16px;
        }

        &-open {
            border-radius: 16px 16px 0 0;
        }

        &-wrap {
            transition: box-shadow .3s linear, border-radius .3s linear;
            box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.08), inset 0px -1px 0px rgba(14, 14, 44, 0.24);
            border-radius: 16px;

            &--open {
                border-radius: 5px 5px 0 0;
                box-shadow: none;
            }
        }
    }

    &-page {
        &-accordion {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
}
</style>